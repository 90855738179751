// 联系我们
<template>
  <div class="contact-wrapper">
    <Bigimg
      title="联系我们"
      :imgUrl="require('@/assets/images/spfw.jpg')"
    ></Bigimg>
    <div class="contact">
      <contactForm />
    </div>
  </div>
</template>
<script>
import contactForm from "@/components/contact-form.vue";
import Bigimg from "@/components/bigimg.vue";

export default {
  components: { contactForm, Bigimg }
};
</script>
<style scoped lang="scss">
.contact {
  margin-bottom: 100px;
}
</style>
